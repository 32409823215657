import React, { useState, useEffect } from 'react';
import './kegiatan.scss';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import FooterId from '../../../footer/FooterId';
import FooterEn from '../../../footer/FooterEn';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Kegiatan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname.split('/').pop();
  const [activeButton, setActiveButton] = useState(currentPath);
  const isIdPath = window.location.pathname.startsWith('/en');

  const handleButtonClick = (componentName) => {
    setActiveButton(componentName);
    navigate(`/en/investor-relation/activity/${componentName.toLowerCase()}`);
    // Scroll only if current scroll position is less than 400px
    if (window.scrollY < 500) {
      window.scrollTo({
        top: 500,
        behavior: 'smooth',
      });
    } else {
      // Scroll to top if current scroll position is above 400px
      window.scrollTo({
        top: 500,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1300,
    });
  }, []);

  return (
    <>
      <div className="mainContainerKegiatan">
        <div className="menuStrukturKegiatan" data-aos="fade-right">
          <div className={`btnNews ${activeButton === 'issuer-announcement' ? 'clicked' : ''}`} onClick={() => handleButtonClick('issuer-announcement')}>
            GMS (General Meeting of Shareholders)
          </div>
          {/* <div className={`btnNews ${activeButton === 'investor-calender' ? 'clicked' : ''}`} onClick={() => handleButtonClick('investor-calender')}>
            Investor Calendar
          </div> */}
          {/* <div className={`btnNews ${activeButton === 'issuer-announcement' ? 'clicked' : ''}`} onClick={() => handleButtonClick('issuer-announcement')}>
            Issuer Announcements
          </div> */}
          <div className={`btnNews ${activeButton === 'public-exposure' ? 'clicked' : ''}`} onClick={() => handleButtonClick('public-exposure')}>
            Public Exposure
          </div>
        </div>
        <div className="outletNews">
          <Outlet />
        </div>
      </div>
      <div className="footer">{isIdPath ? <FooterEn /> : <FooterId />}</div>
    </>
  );
};

export default Kegiatan;
