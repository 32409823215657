import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-creative';
import { EffectCreative, Pagination, Autoplay } from 'swiper/modules';
import './testimonial.scss';
import PlazaAuto from '../../../assets/logoclient/plazaauto.png';
import UnivBsi from '../../../assets/logoclient/bsiuniv.png';
import AOS from 'aos';
import 'aos/dist/aos.css';

const testimonialsData = [
  {
    id: 1,
    name: 'Plaza Auto - Bapak Fuad',
    position: 'IT SPV Group Plaza Auto',
    description:
      '"Kami telah berkolaborasi dengan Remala Abadi sebagai penyedia layanan jaringan internet yang mendukung koneksi jaringan yang kami butuhkan untuk menjalankan sistem ERP perusahaan kami. Sistem ERP kami dapat berjalan dengan baik dan terhubung antara cabang penjualan kami untuk memudahkan proses penjualan dan administrasi."',
    image: PlazaAuto,
  },
  {
    id: 2,
    name: 'Universitas Bina Sarana Informatika',
    position: '',
    description:
      '"PT Remala Abadi Tbk memberikan layanan internet yang baik bagi kami di BSI Group. Tim Support mudah dihubungi dan kami juga dihubungi oleh tim purna jual yang menghubungi dan memperhatikan kami setiap bulannya. Internet dapat menampung keseluruhan interaktif dan digital kegiatan-kegiatan yang kami perlukan di kampus tercinta dan jika ada kendala kami disiagakan oleh tim Customer Service dan kami mendapat solusi cepat dan terbaik untuk permasalahan jaringan kami. Kami berharap dapat melanjutkan pelayanan yang telah kami berikan sejak tahun 2014 bersama PT Remala Abadi Tbk"',
    image: UnivBsi,
  },
];

const Testimonial = () => {
  useEffect(() => {
    AOS.init({
      duration: 1300,
    });
  }, []);

  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#095493"
          fill-opacity="1"
          d="M0,64L21.8,101.3C43.6,139,87,213,131,224C174.5,235,218,181,262,133.3C305.5,85,349,43,393,69.3C436.4,96,480,192,524,208C567.3,224,611,160,655,128C698.2,96,742,96,785,112C829.1,128,873,160,916,154.7C960,149,1004,107,1047,74.7C1090.9,43,1135,21,1178,37.3C1221.8,53,1265,107,1309,128C1352.7,149,1396,139,1418,133.3L1440,128L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"
        ></path>
      </svg>
      <div className="containerTestimoni">
        <div className="headersTestimoni" data-aos="fade-up">
          <h3>TESTIMONI PELANGGAN</h3>
          <h4>Apa yang mereka katakan tentang kami?</h4>
        </div>
        <Swiper
          grabCursor={true}
          effect={'creative'}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ['100%', 0, 0],
            },
          }}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[EffectCreative, Pagination, Autoplay]}
          className="mySwiper2"
          data-aos="fade-up"
        >
          {testimonialsData.map((testimonial) => (
            <SwiperSlide className="sliderCard" key={testimonial.id}>
              <div className="topCard">
                <div className="imgWrapper">
                  <img src={testimonial.image} alt={testimonial.name} />
                </div>
                <div className="identity">
                  <h5>{testimonial.name}</h5>
                  <p>{testimonial.position}</p>
                </div>
              </div>
              <span className="borderLineTesti"></span>
              <div className="bottomCard">
                <p>{testimonial.description}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default Testimonial;
