import React, { useState, useEffect } from 'react';
import './investor.scss';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import MetaDecorator from '../Util/MetaDecorator';
import metaThumbnail from '../data/images/meta/homeimage.png';

const investor = require('../data/json/investor.json');

const Investor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeButton, setActiveButton] = useState('');

  useEffect(() => {
    // Gabungkan logika untuk menentukan activeButton
    const pathArray = location.pathname.split('/').slice(3);
    const initialPath = pathArray.join('/');

    // Cek berbagai kondisi path
    if (initialPath.startsWith('report')) {
      setActiveButton('report');
    } else if (initialPath.startsWith('activity')) {
      setActiveButton('activity');
    } else if (initialPath.startsWith('stock-information/stock-volume')) {
      setActiveButton('stock-information/stock-volume');
    } else if (initialPath.startsWith('memo-information/memo-announcement')) {
      setActiveButton('memo-information/memo-announcement');
    } else if (initialPath.startsWith('announcement/public-announcement')) {
      setActiveButton('announcement/public-announcement');
    }
  }, [location.pathname]);

  const handleButtonClick = (componentName) => {
    setActiveButton(componentName);
    navigate(`/en/investor-relation/${componentName.toLowerCase()}`);

    // Check current scroll position
    const currentScrollPosition = window.scrollY;

    // Scroll only if current scroll position is less than 400px
    if (currentScrollPosition < 400) {
      window.scrollTo({
        top: 400,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    AOS.init({
      duration: 1300,
    });
  }, []);

  return (
    <div>
      <MetaDecorator description={investor.pageDescriptionEn} title={investor.pageTitleEn} imageUrl={metaThumbnail} imageAlt={investor.metaImageAltEn} />
      <div className="contentInvestor" data-aos="fade-down">
        <div className="laporan-content d-flex align-items-center justify-content-center">
          <div className="content-text">
            <h2 className="textHero" data-aos="fade-left">
              UPHOLDING INTEGRITY
            </h2>
            <h2 className="textHero" data-aos="fade-right">
              AND COMMITMENT IN WORK
            </h2>
          </div>
        </div>
        <div className="buttonsInvestor">
          <div className="containerAbout">
            <button className={`btnMenu ${activeButton === 'stock-information/stock-volume' ? 'clicked' : ''}`} onClick={() => handleButtonClick('stock-information/stock-volume')}>
              Share Information
            </button>
            <button className={`btnMenu ${activeButton === 'report' ? 'clicked' : ''}`} onClick={() => handleButtonClick('report/annual-report')}>
              Reports
            </button>
            <button className={`btnMenu ${activeButton === 'activity' ? 'clicked' : ''}`} onClick={() => handleButtonClick('activity/issuer-announcement')}>
              Activities
            </button>
            <button className={`btnMenu ${activeButton === 'memo-information/memo-announcement' ? 'clicked' : ''}`} onClick={() => handleButtonClick('memo-information/memo-announcement')}>
              Memo Information
            </button>
            <button className={`btnMenu ${activeButton === 'announcement/public-announcement' ? 'clicked' : ''}`} onClick={() => handleButtonClick('announcement/public-announcement')}>
              Announcement
            </button>
          </div>
          <div className="isi-content-investor">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Investor;
